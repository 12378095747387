import React from 'react'

import './__styles__/markdown.module.scss'

const _updateInternalUrls = str => {
  const internalUrls = str.match(/href="\/documentation\/\S+"/g)

  if (!internalUrls) return str

  for (const urlString of internalUrls) {
    // get href value
    const oldUrl = urlString.slice(20, -1)
    // remove hash
    const newUrl = oldUrl.replace(/#[0-9]+\.[a-z]{1}/, '')
    const newUrlString = `href="${newUrl}"`
    str = str.replace(urlString, newUrlString)
  }
  return str
}

const MarkdownContent = ({ content }) => {
  const html = _updateInternalUrls(content.childMarkdownRemark.html)

  return (
    <div
      styleName="container"
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  )
}

export default MarkdownContent
