import React from 'react'

import './__styles__/disclaimer.module.scss'

const Disclaimer = () => (
  <div styleName="container">
    <p>
      This CRS guide was produced by CRS professionals and Certified Floodplain
      Managers to help you navigate the Community Rating System. It is not meant
      to replace FEMA's official{' '}
      <a
        rel="noopener noreferrer"
        href="https://www.fema.gov/media-library/assets/documents/8768"
      >
        CRS Coordinator's Manual
      </a>
      , nor should it supersede the instructions given by your ISO/CRS
      Specialist.
    </p>
    <p>
      For additional resources, visit{' '}
      <a rel="noopener noreferrer" href="https://crsresources.org/">
        https://crsresources.org/
      </a>{' '}
      .
    </p>
  </div>
)

export default Disclaimer
