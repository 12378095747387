import React from 'react'

import { Header, Section } from './layout'
import Markdown from './markdown'
import Disclaimer from './disclaimer'

const Series = ({ data }) => (
  <div>
    <Header
      identifier={data.identifier}
      name={data.name}
      intro={data.intro ? data.intro.intro : null}
    />
    <Section>
      {!!data.content && <Markdown content={data.content} />}
      <Disclaimer />
    </Section>
  </div>
)

export default Series
