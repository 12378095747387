import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import DocumentationLayout from '../components/documentation/layout'
import SEO from '../components/seo'
import SeriesContent from '../components/documentation/series'

const Series = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={`${data.contentfulActivityOrder.identifier} ${
          data.contentfulActivityOrder.name
        } | Community Rating System (CRS)`}
        description={
          data.contentfulActivityOrder.intro
            ? data.contentfulActivityOrder.intro.intro
            : ''
        }
        keywords={['community rating system', 'CRS guide', 'CRS documentation']}
      />
      <DocumentationLayout
        data={data.allContentfulActivityOrder.nodes}
        currentItem={`${data.contentfulActivityOrder.identifier} ${
          data.contentfulActivityOrder.name
        }`}
      >
        <SeriesContent data={data.contentfulActivityOrder} />
      </DocumentationLayout>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    contentfulActivityOrder(id: { eq: $id }) {
      name
      identifier
      intro {
        intro
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }

    allContentfulActivityOrder(sort: { fields: identifier }) {
      nodes {
        id
        identifier
        name
        slug
        activities {
          id
          identifier
          name
          slug
          elements {
            id
            identifier
            name
          }
        }
      }
    }
  }
`

export default Series
